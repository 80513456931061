<template>
	<div class="order_wrap px-6">
		<v-container class="">
			<div class="message_box mt-3">
				<span class="txt_body1">
					주문 진행이 불가한 상태입니다.<br />
					자세한 내용은 담당 판매인에게
					<br />
					문의해 주시기 바랍니다.
				</span>
			</div>
		</v-container>
	</div>
</template>

<script>
/**
 * 인증 만료 화면
 */

export default {
	name: 'CustVerifyDeleted'
}
</script>
